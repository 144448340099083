<template>
  <div>
    <b-alert variant="secondary" :show="!items.length">
      <div class="alert-body">
        <feather-icon class="mr-50" icon="InfoIcon" />
        <span>Oturum kaydı bulunmamaktadır </span>
      </div>
    </b-alert>
    <b-list-group>
      <b-list-group-item v-for="(item, key) in items" :key="key">
        <span>{{ item.label }}</span>
        <footer class="blockquote-footer">
          <small class="text-muted">
            <cite title="Source Title">{{ $FormattedDateTime(item.createdAt) }}</cite>
          </small>
        </footer>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  computed: {
    items() {
      return this.$store.getters?.appModalItem?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) ?? [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
