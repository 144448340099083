<template>
  <app-overlay>
    <db-searchable-table
      class="overflow-hidden"
      v-model="filters"
      @changed="getData"
      @row-clicked="showUserMovements"
      :items="items"
      :fields="fields"
      :total-rows="totalRows"
      searchable
      filterable
      hover
    >
      <template slot="customer.company.name" slot-scope="data">
        <span v-if="data.item && data.item.customer && data.item.customer.company.name">
          {{ $fn(data.item.customer.company.name, 20) }}
        </span>
      </template>
      <template #filtercollapse>
        <b-row>
          <b-col md="4">
            <app-select-input v-model="userType" name="Tür" :options="roles" select_label="value" placeholder="Tür Seçiniz..." no-gap @input="getData" append-to-body />
          </b-col>
        </b-row>
      </template>
      <!-- <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="showUserMovements(item)"> Oturum Hareketleri </b-dropdown-item>
        </app-dropdown>
      </template> -->
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import UserMovementsList from "./UserMovementsList.vue";
export default {
  data() {
    return {
      userType: null,
      items: [],
      filters: {},
      totalRows: 0,
      roles: [],
    };
  },
  computed: {
    fields() {
      const getFieldsOfGroup = {
        physician: [{ key: "riskomeremployee.branch.name", label: "Şubesi" }],
        customer: [{ key: "customer.company.name", label: "Firma" }],
      };
      return [
        { key: "createdAt", label: "Giriş", formatDateTime: true, sortable: true },
        { key: "logoutTime", label: "Çıkış", formatDateTime: true, sortable: true },
        { key: "fullname", label: "İsim", sortable: true },
        { key: "ipAddress", label: "IP Adresi" },
        { key: "email", label: "E-Posta" },
        { key: "userRole.value", label: "Rol" },
        ...(getFieldsOfGroup[this?.userType?.group] ?? []),
        /*  { key: "actions", label: "İşlemler" }, */
      ];
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/logs/auth", {
          params: {
            ...this.filters,
            "userRole._id": this?.userType?._id,
            "userRole.group": this.userType?.group,
            searchFields: JSON.stringify(["fullname", "email", "ipAddress"]),
          },
          loading: "table",
        })
        .then((res) => {
          this.items = res.data.data;
          this.totalRows = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRoles() {
      this.$axios
        .get("/permissions/roles")
        .then((result) => {
          this.roles = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showUserMovements(item) {
      this.$showAppModal({ title: "Oturum Hareketleri", component: UserMovementsList, item: item?.movements });
    },
  },
  mounted() {
    this.getRoles();
  },
};
</script>

<style></style>
